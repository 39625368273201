import '../css/app.scss'

import 'lazysizes'
// import Turbolinks from 'turbolinks'
import { Application } from 'stimulus'

import DrawingsController from './controllers/drawings'
import GalleryController from './controllers/gallery'
import CarouselController from './controllers/carousel'


const main = async () => {

  //Turbolinks.start()
  const application = Application.start()
  application.register('drawings', DrawingsController)
  application.register('gallery', GalleryController )
  application.register('carousel', CarouselController )
}

main()