import { Controller } from 'stimulus'
import svg from '../svg.js'
import SVG from 'svg.js'

export default class extends Controller {
  connect () {
    let draw = SVG('drawing').size(window.innerWidth, document.body.clientHeight)
    this.ro = new ResizeObserver(entries => {
      draw.clear()
      draw.size(window.innerWidth, document.body.clientHeight)
      svg(draw)
    })
    this.ro.observe(this.element)
  }
}
