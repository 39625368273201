function svg (draw) {

  setTimeout(() => {
    let startElements = document.querySelectorAll('.start')
    startElements.forEach((startElement, index) => {
      
      const target = startElement.getAttribute('data-start')
      const targetElement = document.querySelector('[data-end="' + target + '"]')

      let startBox = startElement.getBoundingClientRect()
      let scrollYoffset = window.pageYOffset
      let start = { x: startBox.x + startBox.width + 25, y: startBox.y + startBox.height / 2 + scrollYoffset }

      let endElement = targetElement
      let endBox = endElement.getBoundingClientRect()
      let end = { x: endBox.x + endBox.width + 25, y: endBox.y + endBox.height / 2 + scrollYoffset }

      let circlea = draw.circle(7).move(start.x - 3.5, start.y - 3.5).attr({ fill: 'white' })
      let circleb = draw.circle(7).move(end.x - 3.5, end.y - 3.5).attr({ fill: 'white' })

      //var path = draw.path('M 300 400 q 600 300 0 400')
      var path = draw.path('M ' + start.x + ' ' + start.y + ' A -10 -10 90 1 1 ' + end.x + ' ' + end.y)
      path.fill('none')
      path.stroke({ color: 'white', width: 1, linecap: 'round', linejoin: 'round' })
    })
  }, 100)
}

export default svg
