import { Controller } from 'stimulus'
import Flickity from 'flickity'

export default class extends Controller {
    static targets = [ 'carousel' ]  
    connect () {
      this.flkty = new Flickity( this.carouselTarget, {
        cellAlign: 'left',
        contain: true,
        pageDots: true,
        prevNextButtons: false
        
      })

      // setTimeout(() => {
      //   this.flkty.resize()
      //   this.flkty.reloadCells()}
      //   , 0)
    }

    disconnect () {
      this.flkty.destroy()
    }
  
    clickHandler () {
      this.contentTarget.classList.toggle('js-menu-hidden')
    }
  }
  